<template>
  <div class="flex-row">
    <div class="used_left" style="display: flex;flex-direction: column;border-right: 1px solid #ECECEC;">
<!--      <el-input v-model="searchParams.search_value" @change="search('submit')" style="align-self:center;width: 87%;font-size: 0.75rem" size="small" placeholder="请输入关键字查询" prefix-icon="el-icon-search"></el-input>-->
      <el-input v-model="searchParams.search_value" @change="search('submit')" class="input_box" style="margin:1rem;width: 87%;" size="small" placeholder="请输入关键字查询" prefix-icon="el-icon-search">
        <el-button slot="append" @click="search('submit')">搜索</el-button>
      </el-input>
      <div class="used_title">所有文章</div>
        <div v-for="item in tableList" class="used_menu" :class="{'active':activeItem===item.id}" @click="ToDetail(item.id)">
          {{item.title}}
        </div>
    </div>
    <div style="flex: 1;padding:4.5rem 7.5rem;border-left: 1px solid #ECECEC;overflow: auto;">
      <template v-for="item in tableList" style="font-size: 1.2rem;margin:1.5rem 0 ">
        <div v-show="activeItem===item.id">
          <div class="used_title" style="padding-left:0">{{item.title}}</div>
          <div  class="used_rich">
            <div v-html="item.content"></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: "InstructionManual",
  data(){
    return {
        searchParams:{
          search_value:'',
          page_number: 1,
          page_size:20
        },
        //列表数据
        tableList:[],
        //当前激活项
        activeItem:this.$utils.CacheSessionGet('manualId') || '',
    }
  },
  mounted() {
    this.search('load')
  },
  methods:{
    search(type){
      let searchParams=this.$utils.ObjectToJSON(this.searchParams)
      this.$api.post('v1/api/makafin/manual/tabulation',searchParams,
      res=>{
        this.tableList=res.list
        if(type==='load'){
          this.$utils.CacheSessionSet('manual',res.list)
          let manualId=this.$utils.CacheSessionGet('manualId')
          if(manualId){
            this.activeItem=res.list.find(item=>item.id===manualId).id
          }else{
             this.activeItem=res.list[0] ? res.list[0].id :''
          }
        }else{
          this.activeItem=res.list[0] ? res.list[0].id :''
        }
      })
    },
    ToDetail(id){
      // this.$utils.CacheSessionSet('manualId',+id)
      this.activeItem=id
      // this.$router.push({path:'/manual/detail',query:{id:id}})
    }
  },
}
</script>
<style>
.used_rich{
  font-size: 1.125rem;
  line-height: 2;
}
.used_rich ol,.used_rich ol li{
  list-style:decimal ;
}
.used_rich ul,.used_rich ul li{
  list-style:disc ;
}
.input_box{
  border-radius: 1.125rem;
  overflow: hidden;
  border: 1px solid #E4E4E4;
}
.input_box  .el-input__inner{
  border: 0;
}
.input_box .el-input-group__append{
  border-radius: 1.125rem;
}
</style>
<style scoped>
.used_left{
  box-sizing: border-box;
  height: 100%;
  overflow: auto;
  padding-top: 2rem;
}
.used_menu:hover{
  background: #FFF1DB;
  color: #FFA00B;
}
.used_menu.active{
  background: #FFF1DB;
  color: #FFA00B;
}
.used_menu{
  cursor: pointer;
  font-size: 0.875rem;
  padding:0.75rem 2rem;
}
.used_title{
  font-weight: bold;
  font-size: 1.125rem;
  padding:0.75rem 2rem;
}
</style>